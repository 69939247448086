export const DataProductos = 
[
  {
    imagenes: [
      "assets/products/lipocontrast/banner1.png",
      "assets/products/lipocontrast/banner2.png",
      "assets/products/lipocontrast/banner3.png",
      "assets/products/lipocontrast/banner4.png",
      "assets/products/lipocontrast/banner5.jpg"
    ],
    video: "MQz9jmVMa_0",
    titulo: "LIPOCONTRAST",
    descripcion: "Es una nueva técnica para la reducción de grasa localizada mediante un proceso selectivo y no invasivo llamado termo lipólisis de contraste. Este equipo está diseñado para aplicar frío y calor de manera no invasiva sobre la piel y los tejidos subcutáneos para diversas aplicaciones terapéuticas, en particular para procedimientos médicos que incluyan reducción de la capa subcutánea de grasa localizada a través de la lipolisis asistida por frío. El tejido graso es colocado en un aplicador especial y expuesto a un triple choque térmico de acuerdo a unos parámetros de tiempo, velocidad, temperatura y energía muy precisos, controlados automáticamente por 3 softwares.",
    resultados: `<div class='divResults div-center'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/lipocontrast/resultados/res1.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/lipocontrast/resultados/res2.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2 style="text-align: center">INDICACIONES</h2>
                        <ul>
                          <li>Adiposidad localizada mayor a 2.4cm</li>
                          <li>Celulitis (PEFE).</li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div class='divIndicaciones'>
                        <div class='indicaciones' key={index}>
                          <div style={{padding: '0 1em'}}>
                            <img class="imgCelular" src="assets/products/lipocontrast/indicaciones/ind1.png" height="350px" style="border-radius: 20px"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Reducci&oacute;n del tejido graso m&aacute;s r&aacute;pido y eficiente que con la Cryolysis convencional.</li>
                            <li>Mayor efectividad en grasa fibrosa.</li>
                            <li>Evita las experiencias traum&aacute;ticas de los m&eacute;todos invasivos como la liposucci&oacute;n.</li>
                            <li>No altera el tejido colindante como puede ocurrir con otras t&eacute;cnicas.</li>
                            <li>Cuatro aplicadores para utilizar dos de ellos simultanea o independientemente de la manera mas adecuada para cada paciente.</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Ancho: 74 cm</li>
                            <li>Largo: 57 cm</li>
                            <li>Altura: 93 cm</li>
                            <li>Peso con pie y accesorios: 43 kg/ 94 lb</li>
                            <li>Condiciones ambientales de almacenaje y transporte: De -5.0 &deg;C a 60.0 &deg;C (de 23 &deg;F a 140 &deg;F) del 10 al 95% (sin condensaci&oacute;n)</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='accion'>
                <div style="margin-top: 2em; border: solid #17a786 5px; border-radius: 20px">
                  <img class="imgCelular" src="assets/products/lipocontrast/accion/img.jpeg" height="450px" style="border-radius: 20px"/>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/liposoon/baner2.png",
      "assets/products/liposoon/1.jpg",
      "assets/products/liposoon/baner3.png",
      "assets/products/liposoon/baner4.png",
      "assets/products/liposoon/baner5.png",
    ],
    video: "LIl0R1lPfvc",
    titulo: "LIPO SOON",
    descripcion: `Lipo Soon es un ultrasonido focalizado de alta intensidad, también denominado HIFU (High  Intensity Focused Ultrasound), que ofrece resultados más rápidos y eficientes, sin dañar los tejidos subyacentes.<br/>
    Provoca una ablación del tejido adiposo, debido a la vibración molecular ocasionada por las ondas de ultrasonido que se dirigen hacia un único punto, las que aumentan la temperatura de los tejidos e inducen a una necrosis
    por termocoagulación de las células adiposas.`,
    resultados: `<div class='divResults'>
                  <div class='results'>
                    <div>
                      <div class="before-after">
                        <div><span>ANTES</span></div>
                        <div><span>DESPUES</span></div>
                      </div>
                        <img src="assets/products/liposoon/resultados/imagen1.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Adiposidad localizada</li>
                          <li>Reducción de medidas</li>
                          <li>Moldeamiento corporal</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                  <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                    <h2>Caracter&iacute;sticas Generales</h2>
                    <div>
                      <img src="assets/products/liposoon/caracteristicas/imagen1.svg" height="500px"/>
                    </div>
                    <h2>Caracter&iacute;sticas Tecnicas</h2>
                    <ul>
                      <li>Consumo máximo: 0.260 KWh (100V~) – 0.340 KWh (240V~)</li>
                      <li>Ancho: 40 cm</li>
                      <li>Largo: 48 cm</li>
                      <li>Altura: 26 cm</li>
                      <li>Peso sin accesorios: 6.30 kg.</li>
                      <li>Peso con accesorios: 8.20 kg</li>
                    </ul>
                  </div>
                </div>`,
    accion: `<div class='divAccion'>
                <div class='accion'>
                  <div style="margin-top: 2em; border: solid #17a786 5px; border-radius: 20px">
                    <img src="assets/products/liposoon/baner1.png" height="auto" style="border-radius: 15px"/>
                  </div>
                </div>
              </div>`
  },
  {
    imagenes: [
      "assets/products/velox/1.png",
      "assets/products/velox/2.jpg",
      "assets/products/velox/3.png",
      "assets/products/velox/4.png",
      "assets/products/velox/6.png",
      "assets/products/velox/7.png"
    ],
    video: "X70Ft3eqs2M",
    titulo: "VELOX",
    descripcion: `Velox es una plataforma multifuncional, que dispone de recursos de ultrasonido. Posee un manipulo con el área de emisión y la potencia más grande del mercado, reduciendo así el tiempo de tratamiento con seguridad y eficiencia, permitiendo tratar áreas más grandes incluso en amplios índices de grasa localizada. Además, Velox también ofrece terapia combinada con microcorriente, corriente galvánica y estímulo linfático.<br/>Entre sus ventajas están las diversas terapias combinadas, siendo una plataforma que brinda apoyo en el pre y postoperatorio de cirugías plásticas estéticas, garantizando resultados satisfactorios y más bienestar al paciente.<br/>El equipo posee su innovadora “Fórmula Velox”, la que determina con precisión la potencia que debe llegar al tejido a tratar, sin perjudicar los tejidos más profundos.<br/>El profesional tendrá la oportunidad de potenciar los resultados de los tratamientos que realiza con Velox ampliando sus protocolos en Contorno corporal, Adiposidad localizada, Celulitis (PEFE), Permeación transcutánea de principios activos y con opción de diseño de programas personalizados en su exclusivo Programa Especial.`,
    resultados: `<div class='divResults'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before">
                          <div><span>ANTES</span></div>
                        </div>
                        <img src="assets/products/velox/resultados/imagen1.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="after">
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/velox/resultados/imagen2.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Adiposidad localizada</li>
                          <li>Celulitis (PEFE)</li>
                          <li>Pre y post operatorio</li>
                          <li>Permeación transcutanea de principios activos</li>
                          <li>Fibrosis y adherencias</li>
                          <li>Contorno corporal</li>
                          <li>Drenaje linfático</li>
                          <li>Hidrolipoclasia</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                  <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                    <h2>Caracter&iacute;sticas Generales</h2>
                    <ul style="list-style-type: none; padding: 0">
                      <li style="display: flex; margin-bottom: 1em"><div><img src="assets/products/velox/caracteristicas/img1.png" width="35px"/></div><div style="margin-left: 1em">Ultrasonido <span class="text-bold">3MHz</span><br/>Potencia real <span class="text-bold">57W</span><div></li>
                      <li style="display: flex; margin-bottom: 1em"><div><img src="assets/products/velox/caracteristicas/img2.png" width="35px"/></div><div style="margin-left: 1em"><span class="text-bold">ERA 19cm2</span> Manípulo Velox<br/>(controles de comando en el mánipulo)</div></li>
                      <li style="display: flex; margin-bottom: 1em"><div><img src="assets/products/velox/caracteristicas/img3.png" width="35px"/></div><div style="margin-left: 1em">Electroterapia (terapia combinada)<br/>Adipómetro</div></li>
                      <li style="display: flex; margin-bottom: 1em"><div><img src="assets/products/velox/caracteristicas/img2.png" width="35px"/></div><div style="margin-left: 1em"><span class="text-bold">Exclusiva Fórmula Velox</span><br/>(capa adiposa x potencia x área x ERA)</div></li>
                      <li style="display: flex; margin-bottom: 1em"><div><img src="assets/products/velox/caracteristicas/img5.png" width="35px"/></div><div style="margin-left: 1em"><span class="text-bold">12 programas predefinidos</span><br/>Programa personalizado<br/>(memoria para 40 protocolos)</div></li>
                    </ul>
                    <h2>Caracter&iacute;sticas Tecnicas</h2>
                    <ul>
                      <li>Consumo máximo: 0.400KWh</li>
                      <li>Ancho: 31 cm</li>
                      <li>Largo: 40 cm</li>
                      <li>Altura: 17 cm</li>
                      <li>Peso sin accesorios: 2.8 Kg</li>
                      <li>Peso con accesorios: 3.5 Kg</li>
                      <li>Selección automática de tensión: 120V a 220V</li>
                    </ul>
                  </div>
                </div>`,
    accion: `<div class='divAccion'>
                <div class='accion'>
                  <div style="margin-top: 2em; border: solid #17a786 5px; border-radius: 20px">
                    <img class="imgCelular" src="assets/products/velox/accion/banner1.PNG" height="auto" style="border-radius: 20px"/>
                  </div>
                </div>
              </div>`
  },
  {
    imagenes: [
      "assets/products/oxy/banner1.png",
      "assets/products/oxy/banner2.png",
      "assets/products/oxy/banner3.png",
      "assets/products/oxy/banner4.png",
      "assets/products/oxy/banner5.png"
    ],
    video: "-txCEDiWju0",
    titulo: "OXY",
    descripcion: `Oxy es un equipo que está conformado por un sistema de generación de ozono.<br/>
    El módulo generador de ozono en el equipo, proviene de un sistema electrónico que genera alta frecuencia y alta tensión en dos electrodos fijados en el tubo donde circula O2. El campo eléctrico generado a través del principio del efecto corona es quien ha de generar ozono. La técnica utiliza oxígeno medicinal para la producción de ozono.<br/>
    La Ozonoterapia es una técnica conocida y utilizada mundialmente por los médicos debido a las propiedades bactericida, fungicida y virustatica del ozono, la técnica puede ser utilizada de forma aislada o complementaria, en un numero amplio de disfunciones terapéuticas y estéticas.`,
    resultados: `<div class='divResults div-center'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before">
                          <div><span>ANTES</span></div>
                        </div>
                        <img src="assets/products/oxy/resultados/Imagen1.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="after">
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/oxy/resultados/Imagen2.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/oxy/resultados/Imagen3.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Dermatología (fistulas cutáneas/perianales y quirúrgicas, heridas/ulceras y lesiones infectadas)</li>
                          <li>Traumatología y Reumatología (dolencias ortopédicas y osteoartrosis localizada, síndrome de fatiga crónica y fibromialgia)</li>
                          <li>Neurología</li>
                          <li>Urología y Ginecología (vulvovaginitis, endometritis, candidiasis vaginal</li>
                          <li>Geriatría</li>
                          <li>Inmunología</li>
                          <li>Odontología (lesiones de caries primarias)</li>
                          <li>Alopecia</li>
                          <li>Aplicaciones estéticas</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Sistemas de seguridad </li>
                            <li>Recursos extras (Descarte del O₃ residual)</li>
                            <li>Programa inteligente para la producción, eliminación de O₃ (uso en Bag)</li>
                            <li>Filtro catalizador </li>
                            <li>Versatilidad de los parámetros.</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 240 Vac - 0.022 kWh</li>
                            <li>Consumo máximo: 100 Vac - 0.011 kWh</li>
                            <li>Ancho: 36 cm</li>
                            <li>Largo: 24 cm</li>
                            <li>Altura: 16 cm</li>
                            <li>Peso sin accesorios: 2 Kg</li>
                            <li>Peso con accesorios: 3.8 Kg</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion divOxy'>
              <div class="videoOxy">
                <iframe width="100%" height="300px" src="https://www.youtube.com/embed/ToVDKnWRc_o" title="Belle Med" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/artis/banner2.jpg",
      "assets/products/artis/banner3.png",
      "assets/products/artis/banner5.jpg",
      "assets/products/artis/banner6.png",
      "assets/products/artis/banner7.png",
    ],
    video: "B7pHBDfspM0",
    titulo: "SPECTRA ARTIS",
    descripcion: `Spectra Artis es la plataforma más completa de radiofrecuencia que le permite al profesional poder trabajar con radiofrecuencia fraccionada, radiofrecuencia convencional y fotobiomodulación.<br/>
    Artis es una radiofrecuencia fraccionada que promueve la renovación tisular estimulando el colágeno por medio de un daño térmico controlado.  Para mejorar la conductividad los electrodos son bañados en oro. Con ello, produce resultados extremadamente eficaces y    con mucha seguridad en el tratamiento, mejorando considerablemente las arrugas faciales, párpados, periorales, cicatrices y estrías, además de flacidez de piel.<br/>
    Spectra Artis se asemeja al efecto del láser fraccionado promoviendo microlesiones térmicas en el tejido, dejando las áreas adyacentes íntegras, acelerando la cicatrización, ofreciendo resultados 3 veces más rápidos que la radiofrecuencia convencional.<br/>
    Sus módulos de Radiofrecuencia Biodinámica y de Fotobioestimulación están formados por un sistema de cuatro conjuntos básicos: un módulo de generación y control de energía, un módulo de transmisión y aplicación (manopla), un módulo auxiliar llamada placa de retorno y un módulo auxiliar para el control de accionamiento de las aplicaciones (pedal).`,
    resultados: `<div class='divResultsMany'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before">
                          <div><span>ANTES</span></div>
                        </div>
                        <img src="assets/products/artis/resultados/Imagen1.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="after">
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/artis/resultados/Imagen2.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/artis/resultados/Imagen3.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before">
                          <div><span>ANTES</span></div>
                        </div>
                        <img src="assets/products/artis/resultados/Imagen4.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="after">
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/artis/resultados/Imagen5.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Rejuvenecimiento</li>
                          <li>Flacidez Tisular</li>
                          <li>Líneas de Expresión</li>
                          <li>Estrias</li>
                          <li>Cicatrices</li>
                          <li>Mejoras en la textura e irregularidades de la piel</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Rápida respuesta al tratamiento</li>
                            <li>Electrodos descartables</li>
                            <li>Asociacion de radiofrecuencia convencional y fotobiomodulación </li>
                            <li>Sistema confort</li>
                            <li>Electrodo fraccionado matricial y lineal</li>
                            <li>Electrodos que varian entre 0.5 a 3mm</li>
                            <li>Downtime de 1 a 5 dias</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 0.115 KWH.</li>
                            <li>Peso neto: 8,0 kg.</li>
                            <li>Peso bruto: 11,0 kg.</li>
                            <li>Dimensiones: 40 cm de largo, 36 cm de profundidad y 17 cm de altura</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='accion'>
                <div style="margin-top: 2em; border: solid #17a786 5px; border-radius: 20px">
                  <div><img src="assets/products/artis/caracteristicas/Imagen1.png" height="auto" style="border-radius: 20px"/></div>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/rollaction/banner1.png",
      "assets/products/rollaction/banner3.jpg",
      "assets/products/rollaction/banner2.png",
      "assets/products/rollaction/2.jpg",
      "assets/products/rollaction/3.jpg",
    ],
    video: "V4HSecfNp8w",
    titulo: "ROLLACTION",
    descripcion: "Rollaction potencia la fuerza de tus manos. Ahora puedes trabajar el masaje profundo con la misma intensidad durante todo el día sin malas posturas, sin cansarte, protegiendo tus articulaciones del constante desgaste que sufren durante el masaje corporal y reduciendo hasta un 80% el trabajo duro diario.<br/>Rollaction es el exclusivo sistema de masaje mecánico corporal fisio activo inspirado en los movimientos profesionales del masajista, aportando un extra de fuerza y energía en los tratamientos corporales más demandados en cabina.<br/>Su compresión actúa sobre el sistema vascular, muscular y dérmico simultáneamente. Su acción no invasiva, devuelve al organismo el equilibrio y la armonía.<br/>Su mando de control tiene un diseño ergonómico el cual lo hace muy preciso en su trabajo. Dispone de cuatro tamaños de cabezales Roll que se adaptan a todos los tratamientos y áreas de trabajo.",
    resultados: `<div class='divResults'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/rollaction/resultados/1.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/rollaction/resultados/2.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/rollaction/resultados/3.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Tonificación y remodelación corporal</li>
                          <li>Activación circulatoria</li>
                          <li>Disminución de la tensión muscular y eliminación de contracturas musculares</li>
                          <li>Reducción de adiposidad localizada</li>
                          <li>Activación del sistema linfático</li>
                          <li>Masaje deportivo</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Sistema Intensivo de masaje fisioactivo corporal</li>
                            <li>Uso de luz infrarroja emisora de calor para preparación del área muscular</li>
                            <li>Mango de control con diseño ergonómico</li>
                            <li>Sensor de seguridad incorporador en mango de control </li>
                            <li>Cuatro tamaños de cabezales Roll con velocidad de rotación ajustable</li>
                            <li>Facilidad y practicidad de movimientos para el profesional</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Ancho: 49cm</li>
                            <li>Largo: 38cm</li>
                            <li>Alto: 95cm</li>
                            <li>Voltaje: 110 V</li>
                            <li>Frecuencia: 50 Hz</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='accionRollaction'>
                <div style={{padding: '0 1em'}}>
                  <img class="imgCelular" src="assets/products/rollaction/action/action.png" height="350px"/>
                </div>
                <div style="margin-top: 1em">
                  <ul>
                    <li>Acción sobre tejido adiposos: Irrigación del tejido dérmico</li>
                    <li>Acción vascular: mejora del riego venosos y el drenaje linfático</li>
                    <li>Acción muscular: aumento del aporte de nutrientes y oxígeno</li>
                  </ul>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/astutos/banner1.png",
      "assets/products/astutos/1.jpg",
      "assets/products/astutos/banner2.png",
    ],
    video: "lML0-kGjFss",
    titulo: "ASTUTOS",
    descripcion: "Astutos es un equipo que utiliza dióxido de carbono (CO2) como gas medicinal y atóxico a través de aplicación subcutánea para los más diversos tratamientos. Presenta display para visualización de los parámetros de tratamiento tales como: tiempo de infusión, control de flujo y cantidad de gas infiltrado en ml/min, visto que el equipo informa la cantidad máxima recomendada. Ofrece dos programaciones distintas: <span class='bold'>Modo Convencional</span> y <span class='bold'>Modo Comfort</span>.<br/>El modo Convencional proporciona flujo programado por el profesional de acuerdo con el objetivo del tratamiento. El modo Comfort presenta la configuración Ramp que proporciona flujo de gas en forma de rampa, iniciando con un valor mínimo de 20 ml/min hasta el valor máximo programado de 150ml/min. El modo Comfort es indicado para pacientes con diferentes grados de sensibilidad.",
    resultados: `<div class='divResultsMany'>
                    <div class='results'>
                      <div>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/astutos/resultados/res1.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/astutos/resultados/res6.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>PEFE (Celulitis)</li>
                          <li>Adiposidad localizada</li>
                          <li>Flacidez cutánea corporal y facial</li>
                          <li>Estrías</li>
                          <li>Rejuvenecimiento</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Diseño compacto</li>
                            <li>Sistema de seguridad (informa si el equipo posee alimentación de gas o si esta ha sido interrumpida)</li>
                            <li>Control inteligente de flujo que asegura la entrega real de flujo a los tejidos</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 240 Vac - 0.014 kWh</li>
                            <li>Consumo máximo: 100 Vac - 0.009 kWh</li>
                            <li>Medidas: Altura 9 cm - Ancho 17 cm - Largo 19 cm</li>
                            <li>Peso bruto: 1,3 kg.</li>
                            <li>Tensión de operación: 127V a 220V</li>
                            <li>Tensión de salida: 24 Vdc</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class="videoOxy">
                <iframe width="100%" height="300px" src="https://www.youtube.com/embed/Xp480snX1jE" title="Belle Med" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/dualsoon/1.png",
      "assets/products/dualsoon/2.jpg",
      "assets/products/dualsoon/3.png",
      "assets/products/dualsoon/4.jpg",
      "assets/products/dualsoon/5.jpg"
    ],
    video: "lWcQDAGGcGE",
    titulo: "DUAL SOON",
    descripcion: "Dual Soon es un equipo de ultrasonido con opciones de salidas Colimada y Focalizada, ambas con alta potencia y objetivo principal de reducción de grasas localizadas.<br/>El uso del transductor colimado produce energía de alta intensidad y potencias con capacidad para calentamientos elevados de tejidos vivos. En esta modalidad el Dual Soon posee un software denominado ‘Formula Velox’ que auxilia al operador determinando automáticamente la intensidad y el tiempo de aplicación más adecuado para un tratamiento eficaz, seguro y ejecutado en el menor tiempo posible.<br/>El transductor focalizado posee la tecnología HIFU (Ultrasonido focalizado de alta intensidad), y tiene como objetivo el provocar la necrosis del tejido adiposo en capas subdérmicas sin producir daños a los tejidos cutáneos y musculares de manera controlada.",
    resultados: `<div class='divResults div-center'>
                    <div class='results'>
                      <div>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/dualsoon/resultados/1.jpeg" height="350px"/>
                        <div class="after">VODA SPA</div>
                      </div>
                    </div>
                    <div class='results'>
                      <div>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/dualsoon/resultados/2.jpeg" height="350px"/>
                        <div class="after">VODA SPA</div>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>PEFE (Celulitis)</li>
                          <li>Post operatorio</li>
                          <li>Permeación de principios activos</li>
                          <li>Fibrosis y adherencias</li>
                          <li>Contorno corporal y adiposidad localizada</li>
                          <li>Drenaje linfático</li>
                          <li>Protocolo Dual Lipo (uso exclusivo)</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Programa de reducción de medidas en tiempo récord</li>
                            <li>Exclusivo Protocolo Dual Lipo</li>
                            <li>Ajuste de potencia, Start / Stop desde el manipulo</li>
                            <li>Sistema Skin Protect que preserva la epidermis contra quemaduras, garantizando el máximo de seguridad para el profesional y el paciente.</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Ancho: 40cm</li>
                            <li>Largo: 48cm</li>
                            <li>Alto: 26cm</li>
                            <li>Voltaje: 110V – 240V</li>
                            <li>Frecuencia: 50/60 Hz</li>
                            <li>Peso sin accesorios: 8.30kg</li>
                            <li>Peso con accesorios: 9.50kg</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='divResults div-center'>
                <div class='results'>
                  <div>
                    <img src="assets/products/dualsoon/accion/1.png" height="350px"/>
                  </div>
                </div>
                <div class='results'>
                  <div>
                    <img src="assets/products/dualsoon/accion/2.png" height="350px"/>
                  </div>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/spectramedic/1.png",
      "assets/products/spectramedic/2.png",
      "assets/products/spectramedic/3.png",
      "assets/products/spectramedic/4.png",
      "assets/products/spectramedic/5.png",
    ],
    video: "ona_1Xmmr8Q",
    titulo: "SPECTRA MEDIC",
    descripcion: "Spectra Medic presenta los mejores resultados para el tratamiento de la flacidez de la piel, inclusive de la región íntima femenina, a partir del calentamiento controlado y preciso de esas estructuras, proporcionando un aspecto de relleno natural de la región genital desde las primeras sesiones. Adicionalmente, contribuye a la reducción de los síntomas del síndrome genitourinario de la menopausia, al síndrome de relajación vaginal, disfunción sexual, fibrosis y adherencias postoperatorias e incontinencia urinaria de esfuerzo (IUE).<br/>Spectra Medic ofrece alta eficiencia en las aplicaciones debido a un recurso innovador de permanencia térmica automatizada, el sistema ATP (Automated Thermal Permanence) exclusivo de Tonederm. Este sistema garantiza un tratamiento efectivo y con una mayor calidad, pues ejecuta automáticamente el control preciso de la temperatura de la piel y del tiempo de permanencia térmica en la región tratada, factores esenciales al proceso de reestructuración de la piel.<br/>Adicionalmente, cuenta con las funciones de Radiofrecuencia Biodinámica, Fotobioestimulación y con tecnología opcional de plasma, ofreciendo procedimientos seguros y prácticos.",
    resultados: `<div class='divResults div-center'>
                    <div class='results'>
                      <div>
                        <img src="assets/products/spectramedic/resultados/1.png" height="550px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Síndrome de relajación vaginal</li>
                          <li>Síndrome genitourinario de la menopausia</li>
                          <li>Rejuvenecimiento vulvovaginal</li>
                          <li>Fibrosis y adherencias post operatorias</li>
                          <li>Disfunción sexual</li>
                          <li>Incontinencia urinaria de esfuerzo</li>
                          <li>Atrofia vaginal</li>
                          <li>Estética Íntima</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Transductor resistente a procesos de esterilización y desinfección, incluyendo autoclaves.</li>
                            <li>Resultados con menos esfuerzo, reduciendo la necesidad de movimientos amplios.</li>
                            <li>Exclusiva tecnología Thex Coat</li>
                            <li>Transductor ergonómico y electrodo activo anatómico para acceder con más eficiencia a todas las regiones que comprenden el área vaginal.</li>
                            <li>Exclusivo sistema ATP – Automated Thermal Permanence</li>
                            <li>Método de aplicación “4 Quarters”</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Ancho: 43cm</li>
                            <li>Largo: 52cm</li>
                            <li>Alto: 97cm</li>
                            <li>Voltaje: 127V – 240V</li>
                            <li>Frecuencia: 50/60 Hz</li>
                            <li>Peso con accesorios: 45kg</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='divResults div-center'>
                <div class='results'>
                  <div>
                    <img src="assets/products/spectramedic/accion/1.png" height="350px"/>
                  </div>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/spectraface/1.png",
      "assets/products/spectraface/2.jpg",
      "assets/products/spectraface/3.png",
      "assets/products/spectraface/4.jpg",
      "assets/products/spectraface/5.png",
      "assets/products/spectraface/6.jpg",
      "assets/products/spectraface/7.png"
    ],
    video: "xq8uLoCuyDo",
    titulo: "SPECTRA FACE",
    descripcion: "Spectra Face es un equipo de Radiofrecuencia que genera energía no ablativa bajo formas de radiación electromagnética intencional para fines estéticos, formado por un sistema de tres conjuntos básicos:  un módulo de generación y control de energía, un módulo de transmisión y aplicación (manípulo) y un módulo auxiliar llamado placa de retorno.<br/>Permite la programación de tratamientos personalizados en áreas faciales principalmente, con posibilidad de seleccionar hasta 19 protocolos consecutivos. Así mismo, posee animaciones orientadoras de la aplicación, indicando las áreas correctas de cada tratamiento y pantallas cíclicas con consejos y observaciones importantes.<br/>Ofrece una excelente transferencia térmica que ayuda en la disipación de calor producido en la superficie del tejido de tratamiento.<br/>Spectra Face dispone de manoplas de sistema Monopolar y Bipolar.",
    resultados: `<div class='divResultsMany'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectraface/resultados/1.jpg" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectraface/resultados/2.jpg" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Envejecimiento cutáneo (surcos y líneas de expresión)</li>
                          <li>Rejuvenecimiento facial total</li>
                          <li>Lifting de cuello</li>
                          <li>Renovación de escote</li>
                          <li>Rejuvenecimiento de manos</li>
                          <li>Regeneración del área de la frente</li>
                          <li>Atenuación del entrecejo</li>
                          <li>Contorno de ojos</li>
                          <li>Contorno labial</li>
                          <li>Surco naso geniano</li>
                          <li>Contorno facial</li>
                          <li>Lifting malar</li>
                          <li>Reducción de papada</li>
                          <li>Flacidez de papada</li>
                          <li>Acné activo</li>
                          <li>Cicatrices y secuelas de acné</li>
                          <li>Pre-operatorio</li>
                          <li>Post-operatorio</li>
                          <li>Fibrosis y adherencias</li>
                          <li>Flacidez lóbulo de la oreja</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Control de permanencia térmica realizado por el profesional, vía termómetro y técnicas de aplicación.</li>
                            <li>Trata cada región con intensidad de potencia y distribución de energía adecuada.</li>
                            <li>Electrodos con revestimiento Thex Coat®, proporcionando una mayor eficiencia térmica.</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 0.080kWh</li>
                            <li>Medidas: Largo 36cm – Ancho 40cm - Altura 17cm</li>
                            <li>Peso bruto: 6.5 kg</li>
                            <li>Tensión de operación: 127V a 220V</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='divResults div-center'>
                <div class='results'>
                  <div style={{padding: '0 1em'}}>
                    <div class="after">
                      <div><span>MECANISMO DE ACCION BIPOLAR</span></div>
                    </div>
                    <img src="assets/products/spectraface/accion/1.PNG" height="350px"/>
                  </div>
                </div>
                <div class='results'>
                  <div style={{padding: '0 1em'}}>
                    <div class="after">
                      <div><span>MECANISMO DE ACCION MONOPOLAR</span></div>
                    </div>
                    <img src="assets/products/spectraface/accion/2.PNG" height="350px"/>
                  </div>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/spectrag2/1.png",
      "assets/products/spectrag2/2.jpg",
      "assets/products/spectrag2/3.png",
      "assets/products/spectrag2/4.jpg",
      "assets/products/spectrag2/5.png",
      "assets/products/spectrag2/6.jpg",
      "assets/products/spectrag2/7.jpg"
    ],
    video: "CkA8g78z9zk",
    titulo: "SPECTRA G2+",
    descripcion: "Spectra G2+ es un equipo de radiofrecuencia formado por un sistema de cuatro conjuntos básicos: un módulo de generación y control de energía, un módulo de transmisión y aplicación (manopla), un módulo auxiliar llamado placa de retorno y un módulo para auxiliar en el control de accionamiento de las aplicaciones (Pedal).<br/>A través del reconocimiento automático de la manopla conectada, el software de control adapta los parámetros internos del sistema para garantizar la eficiencia y la comodidad de las aplicaciones.<br/>El módulo de transmisión y aplicación (manopla) presenta un radiómetro (sensor de temperatura por infrarrojo) incorporado a cada manopla para medir, continuamente, la temperatura de la superficie de la piel e informar al sistema de control del equipo. Esta funcionalidad elimina la utilización del termómetro manual de temperatura.",
    resultados: `<div class='divResultsMany'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectrag2/resultados/1.jpg" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectrag2/resultados/2.jpg" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectrag2/resultados/3.jpg" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Flacidez y envejecimiento cutáneo (elastosis)</li>
                          <li>Líneas de expresión</li>
                          <li>Paniculopatía Edemato Fibro Esclerótica – PEFE (Celulitis)</li>
                          <li>Cicatrices hipertróficas y queloides</li>
                          <li>Adherencias y fibrosis</li>
                          <li>Analgesia (no relacionada al proceso inflamatorio agudo)</li>
                          <li>Regeneración de tejidos moles (en los periodos de proliferación y remodelación)</li>
                          <li>Acné activa y en fase cicatricial</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Sistema Intelligent Control que regula la potencia aplicada</li>
                            <li>Medidor de temperatura integrado en los manípulos</li>
                            <li>Electrodos con revestimiento Thex Coat®, proporcionando una mayor eficiencia térmica</li>                         
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 0.080kWh</li>
                            <li>Medidas: Largo 40cm – Profundidad: 36cm - Altura 17cm</li>
                            <li>Peso bruto: 11,5 kg</li>
                            <li>Tensión de operación: 127V a 220V</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='divResults div-center'>
                <div class='results'>
                  <div style={{padding: '0 1em'}}>
                    <div class="after">
                      <div><span>MECANISMO DE ACCION BIPOLAR</span></div>
                    </div>
                    <img src="assets/products/spectrag2/accion/1.PNG" height="350px"/>
                  </div>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/spectraplasma/1.png",
      "assets/products/spectraplasma/1.jpg",
      "assets/products/spectraplasma/2.png",
      "assets/products/spectraplasma/2.jpg",
      "assets/products/spectraplasma/3.png",
      "assets/products/spectraplasma/4.png",
      "assets/products/spectraplasma/5.png",
      "assets/products/spectraplasma/6.png",
      "assets/products/spectraplasma/7.png",
      "assets/products/spectraplasma/8.png",
      "assets/products/spectraplasma/9.png"
    ],
    video: "1Zo2TaDRfGw",
    titulo: "SPECTRA PLASMA",
    descripcion: "Spectra Plasma es la tecnología más nueva de Tonederm, que actúa con plasma eléctrico, el cual es un estado donde la materia es ionizada parcialmente, transformándose en un conjunto de iones, átomos, moléculas y electrones libres.  Estos componentes interactúan formando nuevos iones y moléculas, y en esa interacción, liberan mucha energía en forma de luz. El plasma es un excelente conductor eléctrico que favorece a las corrientes eléctricas de alta frecuencia que elevan la temperatura del tejido, estimulando la producción de queratinocitos, ventajosos para los procesos de cura y regeneración de tejidos.<br/>El plasma posee propiedades altamente bactericidas y fungicidas, las cuales se pueden emplear en pre y post procedimientos, así como, una acción ablativa superficial para tratamientos de melanosis solares, manchas seniles, flacidez palpebral, al igual que rejuvenecimiento de manos.<br/>A través de aplicadores especiales y de los controles precisos de energía, Spectra Plasma es capaz de producir dosis fraccionadas de plasma, proporcionándole a los tratamientos estéticos un mayor control, más precisión y seguridad.",
    resultados: `<div class='divResultsMany'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectraplasma/resultados/1.jpg" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectraplasma/resultados/2.jpg" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/spectraplasma/resultados/3.jpg" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Rejuvenecimiento</li>
                          <li>Líneas de expresión</li>
                          <li>Arrugas</li>
                          <li>Flacidez tisular</li>
                          <li>Manchas seniles</li>
                          <li>Remodelamiento dérmico</li>
                          <li>Cicatrices atróficas</li>
                          <li>Drug delivery</li>
                          <li>Melanosis solares</li>
                          <li>Estrias (Fototipos I, II, III)</li>
                          <li>Acne activo</li>
                          <li>Post operatório</li>
                          <li>Pre y post procedimientos estéticos</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Posee una manopla leve y ergonómica garantizando mejor funcionalidad y aplicabilidad.</li>
                            <li>Generación de plasma estable y preciso que garantiza la entrega efectiva de energía.</li>
                            <li>Múltiples punteras con espaciadores y control de pulsos fraccionados, que brindan una amplia gama de tratamientos.</li>                                                 
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 0.080kWh</li>
                            <li>Medidas: Largo 40cm – Ancho 36cm - Altura 17cm</li>
                            <li>Peso bruto: 6.5 kg</li>
                            <li>Tensión de operación: 127V a 220V</li>
                          </ul>
                        </div>
                      </div>`,
    accion:  `<div class='divAccion divOxy'>
              <div class="videoOxy">
                <iframe width="100%" height="300px" src="https://www.youtube.com/embed/WjTmqUh0Ew0" title="Belle Med" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/nioo/1.png",
      "assets/products/nioo/1.jpg",
      "assets/products/nioo/2.png",
      "assets/products/nioo/2.jpg",
      "assets/products/nioo/3.png",
      "assets/products/nioo/3.jpg",
      "assets/products/nioo/4.png",
      "assets/products/nioo/4.jpg",
      "assets/products/nioo/5.png",
      "assets/products/nioo/5.jpg",
      "assets/products/nioo/6.png",
      "assets/products/nioo/6.jpg",
      "assets/products/nioo/7.png",
      "assets/products/nioo/7.jpg",
      "assets/products/nioo/8.png",
      "assets/products/nioo/8.jpg",
      "assets/products/nioo/9.png",
      "assets/products/nioo/9.jpg",
      "assets/products/nioo/10.png",
      "assets/products/nioo/10.jpg",
      "assets/products/nioo/11.png",
      "assets/products/nioo/11.jpg",
      "assets/products/nioo/12.png",
      "assets/products/nioo/12.jpg",
      "assets/products/nioo/13.png",
      "assets/products/nioo/13.jpg",
      "assets/products/nioo/14.png",
      "assets/products/nioo/14.jpg",
      "assets/products/nioo/15.png",
      "assets/products/nioo/16.png",
      "assets/products/nioo/17.png",
      "assets/products/nioo/18.png"
    ],
    video: "ax7VmmfIK5A",
    titulo: "NIOO",
    descripcion: "Nioo Touch Infinity es una estación modular que cuenta con tecnologías consolidadas para tratamientos estéticos y terapéuticos de alta eficacia.<br/Es una plataforma combinable y expandible que permite adoptar tecnologías y tratamientos con infinitas posibilidades, todo en una única estación.<br/>Esta estación reúne algunas de las tecnologías más utilizadas para procedimientos estéticos hechos por profesionales de la salud en tratamientos con fines terapéuticos. En la estación Níoo, todas las tecnologías funcionan a través de la misma interfaz gráfica con una pantalla ‘touch screen’, interactiva y simple de operar.<br/>Los dispositivos que componen la estación Níoo se pueden utilizar de forma individual o con funciones asociadas, incluso tecnologías de aplicadores multifuncionales. Tiene varias configuraciones, en función de los módulos prácticos y accesorios que la componen. Todas las configuraciones posibles deben incluir necesariamente el módulo principal/base.<br/>La configuración más completa reúne cuatro tecnologías: Fotobiomodulación, Radiofrecuencia, Terapia de Vacío, Electroterapia y Ultrasonido HIFU. Cada módulo funcional es responsable de una tecnología y todos están conectados al módulo principal/base que ya incorpora la tecnología de la Fotobiomodulación.",
    resultados: `<div class='divResultsMany'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/nioo/resultados/1.jpg" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/nioo/resultados/2.jpg" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/nioo/resultados/3.jpg" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>Alopecia</li>
                          <li>Acné</li>
                          <li>Reparación de tejido</li>
                          <li>Rejuvenecimiento</li>
                          <li>Celulitis</li>
                          <li>Grasa Localizada</li>
                          <li>Estrías</li>
                          <li>Modelado de abdomen</li>
                          <li>Fortalecimiento muscular</li>
                          <li>Analgesia</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Primera estación multitratamientos modular móvil</li>
                            <li>Posee módulos compactos y versátiles para mayor libertad del profesional</li>
                            <li>Acceso a actualizaciones online de versiones de tratamientos y software</li>
                            <li>Posibilidades infinitas para combinar tecnologías y así disponer de sus propios métodos con total libertad  en tratamientos y resultados individualizados</li>
                            <li>Exclusivos accesorios Trinity Facial y Corporal que combina 3 tecnologías simultáneamente: Radiofrecuencia, Terapia de vacío y Fotobiomodulación</li>
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 0,175 kWh</li>
                            <li>Medidas: Alto 29cm – Ancho 52cm - Profundidad 30cm</li>
                            <li>Peso neto: 9.5 kg </li>
                            <li>Peso bruto: 10 kg</li>
                            <li>Tensión de operación: 127V a 220V</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='divResults div-center'>
                <div class='results'>
                  <div style={{padding: '0 1em'}}>
                    <img src="assets/products/nioo/accion/1.png" height="250px"/>
                  </div>
                </div>
              </div>
            </div>`
  },
  {
    imagenes: [
      "assets/products/cmslim/2.png",
      "assets/products/cmslim/1.jpg",
      "assets/products/cmslim/3.png",
    ],
    video: "SQZTo_0-el0",
    titulo: "CM SLIM",
    descripcion: "CM Slim es un equipo que usa HI – EMT (High Intensity Electromagnetic Muscle Trainer). Esta energía penetra en el tejido muscular más profundo, estimulando y entrenando el musculo mediante programas diseñados de acuerdo a las diferentes áreas del cuerpo y propósitos.<br/>CM Slim permite remodelar el contorno corporal de forma no invasiva, trabajando simultáneamente en construir la musculatura, quemar grasa y acelerar el metabolismo conocido como el efecto “After Burn”. Este último, permite que el organismo permanezca en modo de “quema de calorías” hasta 24 horas después de haberse ejercitado con CM Slim.<br/>CM Slim utiliza un rango especifico de frecuencias que no permiten la relajación muscular entre dos estimulaciones consecutivas. La exposición repetitiva a estos estímulos hará que el tejido muscular se sienta como si se estuviera ejercitando.<br/>Durante solo 30 minutos de tratamiento, CM Slim contrae los músculos más de 20.000 veces dando un estímulo de actividad física intensa. Esto es llamado ejercicio inactivo, teniendo los mismos efectos en el cuerpo de estimulación muscular como de quema de grasa con grandes resultados.",
    resultados: `<div class='divResultsMany'>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/cmslim/resultados/1.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/cmslim/resultados/2.png" height="350px"/>
                      </div>
                    </div>
                    <div class='results'>
                      <div style={{padding: '0 1em'}}>
                        <div class="before-after">
                          <div><span>ANTES</span></div>
                          <div><span>DESPUES</span></div>
                        </div>
                        <img src="assets/products/cmslim/resultados/3.png" height="350px"/>
                      </div>
                    </div>
                </div>`,
    indicaciones: `<div class='ant-row indicaciones'>
                    <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                      <div class='texto'>
                        <h2>INDICACIONES</h2>
                        <ul>
                          <li>HIIT: Quema de grasa</li>
                          <li>Hypertrophy: Volumen muscular</li>
                          <li>Strength: Fortalecimiento muscular</li>
                          <li>Combo 1: Quema de grasa + Volumen muscular</li>
                          <li>Combo 2: Volumen muscular + Fortalecimiento muscular</li>
                        </ul>
                      </div>
                    </div>
                  </div>`,
    caracteristicas: `<div class='ant-row caracteristicas'>
                        <div class="ant-col ant-col-xs-22 ant-col-md-18 ant-col-lg-14">
                          <h2>Caracter&iacute;sticas Generales</h2>
                          <ul>
                            <li>Sistema de refrigeración optimizando que CM Slim sea efectivo y sin tiempos de inactividad.</li>
                            <li>Interfaz interactiva de fácil manejo y operatividad</li>
                            <li>La sesión de tratamiento de CM Slim no requiere anestesia, incisiones o molestias.</li>                          
                          </ul>
                          <h2>Caracter&iacute;sticas Tecnicas</h2>
                          <ul>
                            <li>Consumo máximo: 2.3 kVA</li>
                            <li>Medidas: Alto 42cm – Ancho 66cm - Profundidad 115cm</li>
                            <li>Peso bruto: 70 kg</li>
                            <li>Tensión de operación: 110V</li>
                          </ul>
                        </div>
                      </div>`,
    accion: `<div class='divAccion'>
              <div class='divResults div-center'>
                <div class='results'>
                  <div style={{padding: '0 1em'}}>
                    <div class="after">
                      <div><span>MECANISMO DE ACCION BIPOLAR</span></div>
                    </div>
                    <img src="assets/products/cmslim/accion/1.PNG" height="350px"/>
                  </div>
                </div>
              </div>
            </div>`
  }
]

