import NavbarPage from "../NavbarPage"
import {Row, Col} from 'antd'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'antd/dist/antd.css'; 
import './styles.scss';

export default function QuienesSomos(){

  const productos = [
    {
      imagen: require("../assets/img/quienes/cara1.jpg")
    },
    {
      imagen: require("../assets/img/quienes/1.jpg")
    },
    {
      imagen: require("../assets/img/quienes/2.jpg")
    },
    {
      imagen: require("../assets/img/quienes/3.jpg")
    },
    {
      imagen: require("../assets/img/quienes/5.jpg")
    },
    {
      imagen: require("../assets/img/quienes/6.jpg")
    },
    {
      imagen: require("../assets/img/quienes/7.jpg")
    },
    {
      imagen: require("../assets/img/quienes/8.jpg")
    },
  ]
  
  //Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 3500,
    smartSpeed: 450,
    nav: false,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 3
        }
    }
  };

  return(
    <div className="quienesSomos">
      <NavbarPage/>
      <div className="header">
        <img src={require('../assets/img/quienes/PORTADA.jpg')} alt="bellemed" width='100%'/>
      </div>
      <div className="bodyFotos">
        <Row>
          <Col xs={24}>
            <h2 className='titulo'>QUIENES SOMOS</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
              {productos.map((prod, index) => {
                return(
                  <div class="item">
                    <div class="shadow-effect" style={{backgroundImage: `url(${prod.imagen})`}}>
        
                    </div>
                  </div>
                )
              })}
            </OwlCarousel>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={{span: 22, offset: 1}} lg={{span: 16, offset: 4}} className='vision'>
            <h2>Política de Calidad</h2>
            <p>
              <b>BELLE MED SAS</b> es una compañía distribuidora de equipos para estética manteniéndose en constante actualización con las nuevas tecnologías, garantizando que la calidad de los equipos y su composición sean las más altas y novedosas del mercado, además nuestros clientes cuentan con el respaldo de personal calificado y certificado por las compañías con las que tenemos nexo
            </p>
            <h2>Misión</h2>
            <p>
              Grupo Belle Med Ltda se encuentra en el mercado desde 2004, y con el cambio de razón social a <b>BELLE MED SAS</b> en 2020, seguimos siendo una empresa innovadora y con un amplio sentido del servicio, formado por un grupo de profesionales altamente capacitados y en constante desarrollo, con la experiencia necesaria para ofrecer lo último en tecnología y productos de calidad dirigidos al sector médico y estético. Así mismo, ofrecemos un servicio integral desarrollado a la medida de nuestros clientes.
            </p>
            <h2>Visión</h2>
            <p>
            La empresa <b>BELLE MED SAS</b> se seguirá extendiendo en tecnología y mercado para el 2025 en el área médico estética, buscando posicionarse como marca representativa a nivel nacional, en la participación de congresos y muestras comerciales, que proporcionen al usuario confianza, seguridad y la mayor satisfacción y garantía con el uso de nuestros equipos.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}