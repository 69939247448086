import { Link } from 'react-router-dom'
import { useState } from 'react'
import { CgMenu } from 'react-icons/cg'
import { IoArrowBackCircle } from 'react-icons/io5'
import { Modal } from 'antd'
import Logo from '../assets/img/logo-nobg.png'
import './styles.scss'


export default function NavbarPage(){
  const [modalOpen, setModalOpen] = useState(false)

  return(
    <div className="navbar-container-page">
      <div className="navbar-logo"><img src={Logo} alt="Museo Historia Natural" height="70px"/></div>
      <div className="navbar-items navbar-lg">
        <Link to="/"><IoArrowBackCircle color="#17a786" size={40}/></Link>
      </div>
      <div className="navbar-sm">
        <Link to="/"><IoArrowBackCircle color="#17a786" size={40}/></Link>
      </div>
    </div>
  )
}