import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import userPic from '../assets/img/logo.jpeg';
import './styles.css'

const Categories = () => {
  let navigate = useNavigate();

  const productos = [
    {
      id: "12",
      linea: "multiplataforma",
      titulo: "NIOO",
      imagen: require("../assets/img/categories/nioo.png"),
    },
    {
      id: "6",
      linea: "carboxiterapia",
      marca: "astutos",
      titulo: "astutos",
      imagen: require("../assets/img/categories/astutos.png"),
      video: []
    },
    {
      id: '5',
      linea: "masaje fisioactivo",
      marca: "ROLLACTION",
      titulo: "ROLLACTION",
      imagen: require("../assets/img/categories/rollaction.png"),
    },
    {
      id: "3",
      linea: "ozonoterapia",
      marca: "OXY",
      titulo: "OXY",
      imagen: require("../assets/img/categories/oxy.png"),
      video: [{vid: "BBbLqaeSewM"}]
    },
    {
      id: "0",
      linea: "termolipolisis de contraste",
      marca: "LIPOCONTRAST",
      titulo: "LIPOCONTRAST",
      imagen: require("../assets/img/categories/lipocontrast.png"),
    },
    {
      id: "13",
      linea: "ondas electromagneticas",
      marca: "CM SLIM",
      titulo: "CM SLIM",
      descripcion: '',
      imagen: require("../assets/img/categories/cmslim.png"),
    },
    {
      id: "2",
      linea: "ultrasonido colimado + electroterapia",
      marca: "VELOX",
      titulo: "VELOX",
      imagen: require("../assets/img/categories/velox.png"),
    },
    {
      id: "9",
      linea: "radiofrecuencia",
      marca: "SPECTRA FACE",
      titulo: "SPECTRA FACE",
      imagen: require("../assets/img/categories/spectraface.png"),
    },
    {
      id: "7",
      titulo: "DUAL SOON",
      linea: "ultrasonido colimado + HIFU",
      imagen: require("../assets/img/categories/dualsoon.png")
    },
    {
      id: "1",
      titulo: "LIPO SOON",
      linea: "ultrasonido HIFU",
      imagen: require("../assets/img/categories/liposoon.png")
    },
    {
      id: "8",
      titulo: "SPECTRA MEDIC",
      linea: "Radiofrecuencia GI",
      imagen: require("../assets/img/categories/spectramedic.png")
    },
    {
      id: "4",
      titulo: "SPECTRA ARTIS",
      linea: "Radiofrecuencia Fraccionada",
      imagen: require("../assets/img/categories/spectraartis.png")
    },
    {
      id: "10",
      titulo: "SPECTRA G2+",
      linea: "Radiofrecuencia",
      imagen: require("../assets/img/categories/spectrag2.png")
    },
    {
      id: "11",
      titulo: "SPECTRA PLASMA",
      linea: "Radiofrecuencia",
      imagen: require("../assets/img/categories/spectraplasma.png")
    }
  ]
  
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 3500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    };
    return (
      <div className="contenedorC" id="categories">
        <div className="titleC">
          <h1>NUESTRAS CATEGORIAS</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
              {productos.map((prod, index) => {
                return(
                  <div class="item">
                    <div class="shadow-effect" style={{backgroundImage: `url(${prod.imagen})`}}>
                      
                    </div>
                    <div class="testimonial-name" onClick={() => navigate(`/products${prod.id}`)}>
                      <h3>{prod.titulo.toUpperCase()}</h3>
                      <small>{prod.linea}</small>
                    </div>
                  </div>
                )
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    );
};

export default Categories;