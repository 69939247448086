import { useContext, useEffect, useState, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { AppContext } from '../Provider';
import 'antd/dist/antd.css'; 
import './styles.css';

const carouselImages = [
  {
    image: require('../assets/img/header/banner8.jpg'),
    title: 'CM SLIM',
  },
  {
    image: require('../assets/img/header/banner2.jpg'),
    title: 'LIPO SOON',
  },
  {
    image: require('../assets/img/header/banner7.jpg'),
    title: 'DUAL SOON',
  },
  {
    image: require('../assets/img/header/banner4.jpg'),
    title: 'SPECTRA MEDIC',
  },
  {
    image: require('../assets/img/header/banner5.jpg'),
    title: 'ROLLACTION',
  }
]

const carouselImagesCel = [
  {
    image: require('../assets/img/header/banner8Cel.jpg'),
    title: 'CM SLIM',
  },
  {
    image: require('../assets/img/header/banner2Cel.jpg'),
    title: 'LIPO SOON',
  },
  {
    image: require('../assets/img/header/banner7Cel.jpg'),
    title: 'DUAL SOON',
  },
  {
    image: require('../assets/img/header/banner5Cel.jpg'),
    title: 'ROLLACTION',
  }
]

export default function Header(){
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  return(
    <div id="inicio">
      <div className="whatsapp"><a href="https://wa.me/+573022964155" target="_blank"><img src={require('../assets/img/whatsapp.png')} alt="Belle Med" width="70px"/></a></div>
        {windowWidth.current < 500 ?
          <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
            {carouselImagesCel.map((img, index) => {return(
              <div style={{minHeight: '100vh'}}>
                <div style={{backgroundImage: `url("${img.image}")`, backgroundPosition: 'center', backgroundSize: 'cover', minHeight: '100vh'}}></div>
                <p className="header-title">{img.title}<br/>{/*<span className="header-subtitle">{img.subTitle}</span>*/}</p>
              </div>
            )})} 
          </Carousel>
        :
          <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
            {carouselImages.map((img, index) => {return(
              <div style={{minHeight: '100vh'}}>
                <div style={{backgroundImage: `url("${img.image}")`, backgroundPosition: 'center', backgroundSize: 'cover', minHeight: '100vh'}}></div>
                <p className="header-title">{img.title}<br/>{/*<span className="header-subtitle">{img.subTitle}</span>*/}</p>
              </div>
            )})}
          </Carousel>
        }
      
    </div>
  )
}