import 'antd/dist/antd.css'; 
import './App.css';
import Navbar from './Navbar';
import Header from './Header';
// import Products from './Products';
import About from './About';
import Footer from './Footer';
import Categories from './Categories'

function Home() {
  return (
    <div>
      <Navbar/>
      <Header />
      <div className='fondoUnido'>
        <Categories />
        {/* <Products /> */}
        <About />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
