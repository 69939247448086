import 'antd/dist/antd.css'; 
import './styles.scss';
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs'

export default function Footer(){
  return(
    <div className="contenedorF" id="footer">
      <div className='datos'>
        <p><span className='nombre'>Belle Med SAS</span><br/>
        Calle 127 No. 13 - 96 Of. 101<br/>
        Barrio La Carolina<br/>
        Tel: 6271012<br/>
        Cel y Whatsapp: 302 2964155<br/>
        bellemedsas@gmail.com<br/>
        Bogotá - Colombia<br/>
        Copyright @ 2022 Todos los derechos reservados - beltranweb.tk</p>
      </div>
      <div className='redes'>
        <div>
          <a href="https://www.facebook.com/grupobellemedltda?mibextid=ZbWKwL" target='_blank'><BsFacebook size={30} color='white'/></a>
        </div>
        <div>
          <a href="https://www.instagram.com/bellemedsas/?hl=es" target='_blank'><BsInstagram size={30} color='white'/></a>
        </div>
        <div>
          <a href="https://www.youtube.com/@bellemed2285" target='_blank'><BsYoutube size={30} color='white'/></a>
        </div>
      </div>
    </div>
  )
}