import { useContext, useEffect, useState } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import MyProvider from './Provider';
import Home from './Home'
import Products from './Products'
import QuienesSomos from './QuienesSomos'
import './App.css'

function App() {
  return (
    <MyProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/products:id" element={<Products />}/>
            <Route path="/quienesSomos" element={<QuienesSomos />}/>
        </Routes>
      </BrowserRouter>
    </MyProvider>
  );
}

export default App;