import { Link } from 'react-router-dom'
import { useState } from 'react'
import { CgMenu } from 'react-icons/cg'
import { Modal } from 'antd'
import Logo from '../assets/img/logo-nobg.png'
import './styles.scss'


export default function Navbar(){
  const [modalOpen, setModalOpen] = useState(false)

  return(
    <div className="navbar-container">
      <div className="navbar-logo"><img src={Logo} alt="Belle Med" height="70px"/></div>
      <div className="navbar-items navbar-lg">
        <a href="#inicio">Inicio</a>
        <a href="#categories">Categorias</a>
        <Link to="/quienesSomos">Quienes Somos</Link>
        <a href="#footer">Contáctenos</a>
      </div>
      <div className="navbar-sm">
        <CgMenu color="#094c89" size={30} onClick={() => setModalOpen(true)}/>
      </div>
      <Modal
        title="Museo de Historia Nacional"
        
        style={{ top: 0}}
        width={'100%'}
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <div className="navbar-modal" style={{display: 'flex', flexDirection: 'column'}}>
          <a href="#inicio">Inicio</a>
          <a href="#categories">Categorias</a>
          <Link to="/quienesSomos">Quienes Somos</Link>
          <a href="#footer">Contáctenos</a>
        </div>
      </Modal>
    </div>
  )
}