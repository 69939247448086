import { Button, Tabs, Row, Col } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from '../Provider';
import { Carousel } from 'react-responsive-carousel';
import { TbArrowBack } from 'react-icons/tb'
import {DataProductos} from '../productos'
import NavbarPage from '../NavbarPage'
import OwlCarousel from 'react-owl-carousel';
import ReactHtmlParser from 'react-html-parser';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'antd/dist/antd.css'; 
import './styles.scss';

// const Prods = JSON.parse(Productos)

//Owl Carousel Settings
const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 0,
  autoplay: true,
  dots: true,
  autoplayTimeout: 3500,
  smartSpeed: 450,
  nav: false,
  responsive: {
      0: {
          items: 1
      },
      600: {
          items: 2
      },
      1000: {
          items: 3
      }
  }
};

export default function Products(props){
  const [state, setState] = useContext(AppContext)
  const [load, setLoad] = useState(true)
  const [filterProd, setFilterProd] = useState([])
  let navigate = useNavigate();
  let { id } = useParams()

  useEffect(() => {
    setFilterProd(DataProductos[id])
  }, [])

  useEffect(() => {
    if(filterProd?.imagenes){
      setLoad(false)
    }
  }, [filterProd])

  const tabsMenu = [
    {
      id: 1,
      label: 'Resultados',
      children: <div>{ReactHtmlParser(filterProd?.resultados)}</div>
    },
    {
      id: 2,
      label: 'Indicaciones',
      children: <div>{ReactHtmlParser(filterProd?.indicaciones)}</div>
    },
    {
      id: 3,
      label: 'Características',
      children: <div>{ReactHtmlParser(filterProd?.caracteristicas)}</div>
    },
    {
      id: 4,
      label: 'Mecanismo de acción',
      children: <div>{ReactHtmlParser(filterProd?.accion)}</div>
    }
  ]

  return(
    <div id="products">
      {load ?
      <div style={{minHeight: '100vh', backgroundColor: '#17a786', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '3em', fontWeight: 'bold'}}>
        Cargando...
      </div>
      :
      <div className="product-container">
        <NavbarPage />
        <Row>
          <Col xs={24}>
            <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
              {filterProd?.imagenes?.map((prod, index) => {
                return(
                  <div class="item" key={index}>
                    <div class="shadow-effect" style={{backgroundImage: `url(${prod})`}}>
        
                    </div>
                  </div>
                )
              })}
            </OwlCarousel>
          </Col>
        </Row>
        <Row style={{padding: '2em 0'}}>
          {filterProd?.video === '' ? null :
          <Col xs={{span:20, offset:2}} md={{span:10, offset:1}}>
            <iframe width="100%" height="400px" src={`https://www.youtube.com/embed/${filterProd?.video}`} title="Belle Med" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </Col>}
          <Col xs={{span:20, offset:2}} md={filterProd?.video === '' ? {span:16, offset:4} : {span: 10, offset:1}} >
            <h2 style={{fontSize: '2em'}}>{filterProd?.titulo}</h2>
            <p align="justify" style={{fontSize: '1.3em'}}>
              {ReactHtmlParser(filterProd?.descripcion)}
            </p>
          </Col>
        </Row>
        <Tabs
          size={'large'}
          type='line'
          tabBarStyle={{color: '#17a786'}}
          defaultActiveKey="1"
          centered
          items={tabsMenu.map((tab, i) => {
            return {
              label: tab.label,
              key: tab.id,
              children: tab.children,
            };
          })}
        />
      </div>
      }
    </div>
  )
}