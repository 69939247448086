import Bounce from 'react-reveal/Bounce';
import {Row, Col} from 'antd'
import 'antd/dist/antd.css'; 
import './styles.scss';

export default function About(){
  return(
    <div className="contenedorA" id="about">
      <Bounce right cascade>
        <Row>
          <Col xs={24}>
            <h2 className='titulo'>ACERCA DE BELLE MED</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={24} className='video'>
            <iframe width="380" height="350" src="https://www.youtube.com/embed/I8UopN1f_sc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </Col>
        </Row>
        <Row className="secondA">
          <Col xs={24} md={{span: 22, offset: 1}} lg={{span: 16, offset: 4}}>
            <p>
              Belle Med SAS es una empresa creada en 2004, dedicada a la comercialización y distribución de tecnología médica y estética con altos parámetros de calidad. Somos representantes exclusivos para Colombia de la firma <b>TONEDERM</b> de Brasil, <b>CLINIPRO</b> y <b>ROSS</b> de España, y <b>DAEYANG</b> de Corea.
            </p>
            <p>
              Belle Med SAS, cuenta con un grupo interdisciplinario altamente calificado que nos permite ofrecer a nuestros clientes un alto desempeño y seguridad clínica y técnica. Es nuestro principal objetivo, brindarle a usted, la posibilidad de adquirir equipos con tecnología de avanzada, que garantizan un excelente desempeño, confort y calidad, obteniendo reconocimiento y rentabilidad con lo cual estamos seguros que seremos su mejor opción.
            </p>
            <p>
              Usted es nuestra razón principal, y por ello siempre estamos dispuestos a brindarle una excelente relación centrada en su satisfacción.
            </p>
          </Col>
        </Row>
      </Bounce>
    </div>
  )
}